@font-face {
  font-family: Chomsky;
  src: url("Chomsky.eff408eb.otf");
}

body {
  background-color: #dcdcdc;
  margin: 0;
}

#app-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
  margin: 20px;
}

p {
  text-align: center;
}

#opening {
  color: #fff;
  z-index: 2;
  background-image: linear-gradient(0deg, #65a2bb00, #65a2bb 10%);
  margin: 0 0 -15px;
  padding: 20px;
}

#chest-image {
  object-fit: cover;
  aspect-ratio: 2.2;
  width: 100%;
}

#post-image-text {
  z-index: 2;
  background: linear-gradient(0deg, #dcdcdc 70%, #dcdcdc00);
  margin: -20px 0 20px;
  padding-top: 30px;
}

.egg-image {
  width: 40%;
}

#species-container {
  width: 60%;
  margin-bottom: 10px;
}

.species-select-radio {
  display: none;
}

.species-select {
  border-radius: 15px;
  align-items: center;
  display: flex;
}

.species-select.active {
  background: linear-gradient(-45deg, #c2ac4a 10%, #fff 50%, #c2ac4a 90%) 0 0 / 300% 100%;
  animation: 8s infinite alternate sheen;
}

@keyframes sheen {
  0% {
    background-position-x: 90%;
  }

  50% {
    background-position-x: 10%;
  }

  100% {
    background-position-x: 90%;
  }
}

.species-name {
  width: -moz-fit-content;
  width: fit-content;
}

#success-message {
  margin-top: 0;
  margin-bottom: 0;
}

#intro-container {
  background-color: #e0e0e0;
  max-width: 450px;
}

.clipping {
  background-color: #d4c2bd;
  width: 95%;
  margin-bottom: 7rem;
  margin-left: 2.5%;
  box-shadow: 0 0 20px gray;
}

.title {
  margin-bottom: 0;
  font-family: Chomsky;
  font-size: 4rem;
  font-weight: normal;
}

.tagline {
  margin: 0;
}

.article {
  text-align: left;
}

.question {
  font-size: 2rem;
}

.answer {
  font-size: 1.5rem;
}

#signup {
  flex-direction: column;
  max-width: 600px;
  padding: 1rem;
  display: flex;
}

.lead-bubble {
  align-items: center;
  display: flex;
}

.lead-picture {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  display: flex;
}

.lead-name {
  width: 7rem;
}

#phonenumber {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#button-container {
  justify-content: center;
  display: flex;
}

select, option {
  text-align: right;
  font-size: 1.2rem;
}

input {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

#country-code-input, #phone-number-input {
  padding: .5rem;
}

#join-button {
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

/*# sourceMappingURL=index.67012674.css.map */
