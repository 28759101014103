@font-face {
  font-family: Chomsky;
  src: url(/assets/Chomsky.otf);
}

body {
  margin: 0;
  background-color: rgb(220, 220, 220);
}

#app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
  margin: 20px;
}

p {
  text-align: center;
}

#opening {
  background-image: linear-gradient(
    0deg,
    rgba(101, 162, 187, 0),
    rgba(101, 162, 187, 1) 10%
  );
  margin: 0;
  padding: 20px;
  color: white;
  margin-bottom: -15px;
  z-index: 2;
}

#chest-image {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 2.2;
}

#post-image-text {
  margin-top: -20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  padding-top: 30px;
  background: linear-gradient(
    0deg,
    rgba(220, 220, 220, 1) 70%,
    rgba(220, 220, 220, 0)
  );
  z-index: 2;
}

.egg-image {
  width: 40%;
}

#species-container {
  margin-bottom: 10px;
  width: 60%;
}

.species-select-radio {
  display: none;
}

.species-select {
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.species-select.active {
  background: linear-gradient(
    -45deg,
    rgba(194, 172, 74, 1) 10%,
    rgba(255, 255, 255, 1) 50%,
    rgba(194, 172, 74, 1) 90%
  );
  background-size: 300% 100%;
  animation: sheen 8s infinite alternate;
}

@keyframes sheen {
  0% {
    background-position-x: 90%;
  }
  50% {
    background-position-x: 10%;
  }
  100% {
    background-position-x: 90%;
  }
}

.species-name {
  width: fit-content;
}

#success-message {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Intro page */

#intro-container {
  background-color: rgb(224, 224, 224);
  max-width: 450px;
}

.clipping {
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 7rem;
  background-color: #d4c2bd;
  box-shadow: 0px 0px 20px grey;
}

.title {
  font-family: Chomsky;
  font-weight: normal;
  font-size: 4rem;
  margin-bottom: 0rem;
}

.tagline {
  margin: 0rem;
}

.article {
  text-align: left;
}

.question {
  font-size: 2rem;
}

.answer {
  font-size: 1.5rem;
}

/* sign up page */

#signup {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 600px;
}

.lead-bubble {
  display: flex;
  align-items: center;
}

.lead-picture {
  border-radius: 50%;
  background-color: white;
  border: solid 2px black;
  height: 8rem;
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lead-name {
  width: 7rem;
}

#phonenumber {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#button-container {
  display: flex;
  justify-content: center;
}

select,
option {
  font-size: 1.2rem;
  text-align: right;
}

input {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

#country-code-input {
  padding: 0.5rem;
}

#phone-number-input {
  padding: 0.5rem;
}

#join-button {
  margin-bottom: 3rem;
  font-size: 1.2rem;
}
